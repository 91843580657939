<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <br />

    <v-card class="card">
      <v-card-title class="heading justify-center">Status Wise Report</v-card-title>
      <div class="">
        <div class="add-section">
          <div class="d-flex">
            <v-col>
              <label class="add-text">Series</label>
              <v-autocomplete
                v-model="series"
                placeholder="Select Series"
                :items="series_data"
                item-text="name"
                item-value="id"
                outlined
                dense
                class="text"
                :rules="[rules.required]"
                clearable
                required
              ></v-autocomplete>
            </v-col>
            <v-col>
              <label>Department</label>
                    <v-autocomplete
                v-model="dept"
                placeholder="Select Department"
                :items="dept_list"
                item-text="name"
                item-value="id"
                outlined
                dense
                class="text"
                :rules="[rules.required]"
                clearable
                required
              ></v-autocomplete>
              </v-col>
             <v-col>
              <label>Status</label>
                <v-autocomplete
                v-model="status"
                placeholder="Select Satus"
                :items="statuslist"
                item-text="name"
                item-value="id"
                outlined
                dense
                class="text"
                :rules="[rules.required]"
                clearable
                required
              ></v-autocomplete>
             
            </v-col>
          </div>
          <div>
            <center>
              <v-btn color="success" class="mb-4" @click="dailyData1"
                >Fetch Data</v-btn
              >
            </center>
          </div>
        </div>
        <v-data-table
          :headers="headers1"
          :search="search"
          :items="tabledata"
          class="elevation-5 ma-2"
        >
          <template v-slot:top>
            <v-toolbar flat color="white" align="center" justify="center">
              <v-toolbar-title>Status Wise Report</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <download-excel
                class="ma-3"
                v-if="tabledata.length != 0"
                color="primary"
                dark
                :data="tabledata"
                :fields="fields1"
                worksheet="My Worksheet"
                name="Status_Wise_Report.xls"
              >
                <v-btn color="success" dark class="mb-2">
                  <v-icon dark>mdi-file-excel</v-icon>
                </v-btn>
              </download-excel>
            </v-toolbar>
          </template>
          <template v-slot:item.date_of_entry="{ item }">
            {{
              item.date_of_entry.split("T")[0].split("-")[2] +
              "-" +
              item.date_of_entry.split("T")[0].split("-")[1] +
              "-" +
              item.date_of_entry.split("T")[0].split("-")[0]
            }}
          </template>
        </v-data-table>
      </div>
    </v-card>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
  data: () => ({
    fields: {
      "Date Of Entry": {
        field: "date_of_entry",
        callback: (item) => {
          return item == ""
            ? ""
            : item.split("T")[0].split("-")[2] +
                "-" +
                item.split("T")[0].split("-")[1] +
                "-" +
                item.split("T")[0].split("-")[0];
        },
      },
      statuslist:[],
      Title: "title",
      Author: "author",
      Department: "dept",
      Publisher: "publisher",
      Price: "price",
      "No Of Copies": "copies",
      "Total Price": "total_price",
      Status:"status",
    },
     fields1: {
         "Accession No":"acc_no",
         Title: "title",
      "Date Of Entry": {
        field: "date_of_entry",
        callback: (item) => {
          return item == ""
            ? ""
            : item.split("T")[0].split("-")[2] +
                "-" +
                item.split("T")[0].split("-")[1] +
                "-" +
                item.split("T")[0].split("-")[0];
        },
      },
      
      Author: "author",
      prn:"prn",
      Name:"name",
      IssueDate:"issuedate",
      Department: "dept",
      Publisher: "publisher",
      Price: "price",
      Status:"status",
      
    },
    dept: null,
    usertype: null,
    valid: false,
    overlay: false,
    userid: "",
    bookitemid: "",
    selected: [],
    errorMsg: {
      roleLinkId: false,
    },
    rules: {
      required: (value) => !!value || "Required.",
    },
    tabledata: [],
    snackbar: false,
    snackbar_msg: "",
    color: "",
    search: "",
    org: "",
    status:"",
    dialog: false,
    fromDateMenu: false,
    fromDateVal: [],
    startDate: "",
    endDate: "",
    dailyData: [],
    series_data: [],
    usertype_data: [],
    minDate: "2020-01-05",
    maxDate: new Date(),
    series: "",
    no_of_days: 90,
    title_wise: true,
    copy_wise: false,
    flag:true,
    headers: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
      {
        text: "Date Of Entry",
        align: "left",
        sortable: true,
        value: "date_of_entry",
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Author",
        align: "left",
        sortable: true,
        value: "author",
      },
      {
        text: "Department",
        align: "left",
        sortable: true,
        value: "dept",
      },
      {
        text: "Publisher",
        align: "left",
        sortable: true,
        value: "publisher",
      },
      {
        text: "Price",
        align: "left",
        sortable: true,
        value: "price",
      },
      {
        text: "No Of Copies",
        align: "left",
        sortable: true,
        value: "copies",
      },
      {
        text: "Total Price",
        align: "left",
        sortable: true,
        value: "total_price",
      },
       {
        text: "Status",
        align: "left",
        sortable: true,
        value: "status",
      },
    ],

     headers1: [
      {
        text: "Sr no",
        align: "left",
        sortable: true,
        value: "srno",
      },
       {
        text: "Accession No",
        align: "left",
        sortable: true,
        value: "acc_no",
      },
      {
        text: "Date Of Entry",
        align: "left",
        sortable: true,
        value: "date_of_entry",
      },
      {
        text: "Title",
        align: "left",
        sortable: true,
        value: "title",
      },
      {
        text: "Author",
        align: "left",
        sortable: true,
        value: "author",
      },
      {
        text: "PRN / EMP Code",
        align: "left",
        sortable: true,
        value: "prn",
      },
      {
        text: "Name",
        align: "left",
        sortable: true,
        value: "name",
      },
      {
        text: "Issue Date",
        align: "left",
        sortable: true,
        value: "issuedate",
      },
      {
        text: "Department",
        align: "left",
        sortable: true,
        value: "dept",
      },
      {
        text: "Publisher",
        align: "left",
        sortable: true,
        value: "publisher",
      },
      {
        text: "Price",
        align: "left",
        sortable: true,
        value: "price",
      },
       {
        text: "Status",
        align: "left",
        sortable: true,
        value: "status",
      },
      
    ],
  }),
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format/do something with date
    },
    filteredDesserts() {
      return this.dailyData.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  mounted() {
    this.onLoad();
  },
  methods: {
    group(value) {
        this.tabledata=[];
      if (value == "title_wise") {
        this.copy_wise = false;
        this.flag=true;
      }
      if (value == "copy_wise") {
        this.title_wise = false;
        this.flag=false;
      }
    },
    dailyData1() {
       // alert("in get status")
       if(this.status != ""){
      this.overlay = true;

      const data = {
        series_id: this.series,
        dept_id:this.dept,
        status_id:this.status,
       // flag:this.flag
      };
      axios
        .post("/Librarian/get_statuswise_data", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.tabledata = res.data.data;
            if(this.tabledata == null){
                this.showSnackbar("#b71c1c", "No Books Found with Selected Status");
            }
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {});
       }else{
          this.showSnackbar("#b71c1c", "Please Select Status");
       }
    },
    onLoad() {
      this.overlay = true;
      axios
        .post("/Librarian/getstatusData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.overlay = false;
            this.series_data = res.data.series_data;
            this.series_data.push({ name: "ALL", id: "ALL" });
            this.series = "ALL";
            this.statuslist = res.data.statuslist;
            this.dept_list= res.data.dept_list;
            this.dept_list.push({ name: "ALL", id: "ALL" });
            this.dept = "ALL";
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        })
        .finally(() => {
          // var overlay = false;
        });
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      // this.$refs.form.reset()
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-icon-size {
  font-size: 20px;
}
.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}
.edit-avatar:hover {
  background-color: #b0bec5;
}
.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}
.edit-v-icon:hover {
  color: white;
}
.fields {
  padding: 0px !important;
}
.cus-card {
  background: #d3d3d33d;
  margin: 1rem;
  padding: 1rem;
  border: 2px solid gray;
}
.c-label {
  font-weight: bold;
}
.add-section {
  display: block;
  margin: 1rem;
}
.text >>> .v-input__slot {
  background: white !important;
  border-color: hsla(190, 99%, 30%, 1) !important;
}
.elevation-1 /deep/ tr {
  white-space: nowrap !important;
}
.d-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem;
}
.checkbox {
  margin: 1rem;
  border: 1px solid;
  background: #d3d3d380;
}
.checkbox .box {
  margin-top: 5px;
}
.checkbox /deep/ .v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
